body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Roboto Condensed", "Rubik", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
}

* {
    font-family: "Roboto", "Roboto Condensed", "Rubik", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: 0.1s;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

@media (max-width: 768px) {
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
}

@media (max-width: 425px) {
    ::-webkit-scrollbar {
        width: 0;
    }
}

::-webkit-scrollbar-track-piece {
    background-color: #F3F3F3;
    border-radius: 4px;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

::-webkit-scrollbar-track {
    border-radius: 4px;

}

::-webkit-scrollbar-thumb:vertical {
    border-radius: 4px;
    height: 5px;
    background-color: #D0D0D0;
}

::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
    height: 5px;
    background-color: #D0D0D0;
}